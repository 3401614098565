import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import GenerateSection from './generateSections';

export default function PageTemplate({ data }) {
  const { sections, bannerData, navbar } = data.contentfulPage;
  return (
    <Layout navName={navbar} bannerData={bannerData}>
      {data.contentfulPage.seoMetadata && (
        <SEO
          title={data.contentfulPage.seoMetadata.title}
          description={data.contentfulPage.seoMetadata.description}
        />
      )}
      {sections.map((section) => (
        <GenerateSection key={section.id} section={section} />
      ))}
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String) {
    contentfulPage(id: { eq: $id }) {
      id
      internalName
      slug
      navbar
      seoMetadata {
        id
        title
        description
        keywords
      }
      sections {
        __typename
        ... on ContentfulCustomHtml {
          ...ContentfulCustomHtmlFragment
          id
        }
        ... on ContentfulHeroSection {
          ...ContentfulHeroSectionFragment
          id
        }
        ... on ContentfulSideBySideGroupSection {
          ...ContentfulSideBySideGroupSectionFragment
          id
        }
        ... on ContentfulGridSection {
          ...ContentfulGridSectionFragment
          id
        }
        ... on ContentfulGallerySection {
          ...ContentfulGallerySectionFragment
          id
        }
        ... on ContentfulRichTextSection {
          ...ContentfulRichTextSectionFragment
          id
        }
        ... on ContentfulFormSection {
          ...ContentfulFormSectionFragment
          id
        }
        ... on ContentfulTestimonialSection {
          ... ContentfulTestimonialSectionFragment
        }
        ... on ContentfulFaqCollection {
          ...ContentfulFaqCollectionFragment
        }
        ... on ContentfulIndexedContentSection {
          ...ContentfulIndexedContentSectionFragment
        } 
        ... on ContentfulContributorCardsSection {
          ...ContentfulContributorCardsSectionFragment
        }
      }
      bannerData {
        bannerImage {
          gatsbyImageData
        }
        bannerText {
          raw
        }
        internalName
        link
        label
      }
    }
  }
`;
